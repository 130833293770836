export default {
    name: 'InputFile',
    props: {
        inputclass: {
            type: String,
            default: ''
        },
        accept: {
            type: String,
            default: '*'
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
};