import moment from 'moment';
import {
    CAODEFINATIONSLIST_CAOPAGE,
    CAODEFINATIONSLIST_CAOSEARCH,
    CAODEFINATIONSLIST_YEAR,    
} from '../actions/caodefinationslistactions';

const state = {
    caosearch: '',
    caopage: 0,
    year: new Date().getFullYear(),    
};

const getters = {
    caopage: state => state.caopage,
    caosearch: state => state.caosearch,
    year: state => state.year,
};

const actions = {
    [CAODEFINATIONSLIST_CAOSEARCH]: ({ commit }, caosearch) => {
        commit(CAODEFINATIONSLIST_CAOSEARCH, caosearch);
    },
    [CAODEFINATIONSLIST_CAOPAGE]: ({ commit }, caopage) => {
        commit(CAODEFINATIONSLIST_CAOPAGE, caopage);
    },
   
    [CAODEFINATIONSLIST_YEAR]: ({ commit }, year) => {
        commit(CAODEFINATIONSLIST_YEAR, year);
    },
};

const mutations = {
    [CAODEFINATIONSLIST_CAOSEARCH]: (state, caosearch) => {
        state.caopage = 0;
        state.caosearch = caosearch;
    },
    [CAODEFINATIONSLIST_CAOPAGE]: (state, caopage) => {
        state.caopage = caopage;
    },    
    [CAODEFINATIONSLIST_YEAR]: (state, year) => {
        state.caopage = 0;
        state.year = year;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};