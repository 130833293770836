import axios from 'axios';

export default {
    name: 'LeaveStatusTable',
    props: {
        value: {
            type: Object
        },
        showCurrentRequest: {
            type: Boolean,
            default: true
        },

    },
    data() {
        return {
           
            list: null
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.list = this.value;
           
        },
        
    }

}