export default {
    components: {
    },
    props: {
        value:[String,Number],
        name: {
            type: String
        },
        valueclass: {
            type: String,
            default: 'tx-gray-800'
        }
    },
    name: 'ReadonlyText',
    data() {
        return {
        };
    },
};