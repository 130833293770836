import moment from 'moment';
import {
    PROJECTHOURSLIST_PAGE,
    PROJECTHOURSLIST_SEARCH,
    PROJECTHOURSLIST_YEAR,
    PROJECTHOURSLIST_PERIOD
} from '../actions/projecthourslistactions';

const state = {
    search: '',
    page: 0,
    period: moment().month() + 1,
    year: moment().year(),
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    period: state => state.period,
    year: state => state.year,
};

const actions = {
    [PROJECTHOURSLIST_SEARCH]: ({ commit }, search) => {
        commit(PROJECTHOURSLIST_SEARCH, search);
    },
    [PROJECTHOURSLIST_PAGE]: ({ commit }, page) => {
        commit(PROJECTHOURSLIST_PAGE, page);
    },
    [PROJECTHOURSLIST_PERIOD]: ({ commit }, period) => {
        commit(PROJECTHOURSLIST_PERIOD, period);
    },
    [PROJECTHOURSLIST_YEAR]: ({ commit }, year) => {
        commit(PROJECTHOURSLIST_YEAR, year);
    },
};

const mutations = {
    [PROJECTHOURSLIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [PROJECTHOURSLIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [PROJECTHOURSLIST_PERIOD]: (state, period) => {
        state.page = 0;
        state.period = period;
    },
    [PROJECTHOURSLIST_YEAR]: (state, year) => {
        state.page = 0;
        state.year = year;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};