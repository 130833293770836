import { SUBMITTEDTIMELIST_SEARCH, SUBMITTEDTIMELIST_PAGE, SUBMITTEDTIMELIST_STATUS } from '../actions/submittedtimelistactions';

const state = { search: '', page: 0, status: null };

const getters = {
    page: state => state.page,
    search: state => state.search,
    status: state => state.status
};

const actions = {
    [SUBMITTEDTIMELIST_SEARCH]: ({ commit }, search) => {
        commit(SUBMITTEDTIMELIST_SEARCH, search);
    },
    [SUBMITTEDTIMELIST_PAGE]: ({ commit }, page) => {
        commit(SUBMITTEDTIMELIST_PAGE, page);
    },
    [SUBMITTEDTIMELIST_STATUS]: ({ commit }, status) => {
        commit(SUBMITTEDTIMELIST_STATUS, status);
    },
};

const mutations = {
    [SUBMITTEDTIMELIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [SUBMITTEDTIMELIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [SUBMITTEDTIMELIST_STATUS]: (state, status) => {
        state.status = status;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};