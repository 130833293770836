export default {
    name: 'InputNumber',
    props: {
        value: Number,
        name: {
            type: String,
            default: '-'
        },
        inputclass: {
            type: String,
            default: ''
        },
        readonly: { 
            type: Boolean,
            default: false
        }
    },
};