import navmenu from '../navmenu/navmenu.vue';
import { AUTH_LOGOUT } from '../../store/actions/authactions';
import { mapActions, mapGetters } from 'vuex';


export default {
    components: {
        navmenu
    },
    name: 'App',
    computed: {
        ...mapGetters('auth', ['isAuthenticated', 'user', 'testenvironment'])
    },
    methods: {
        logout: function () {
            this.doLogout()
                .then(() => {
                    this.$router.go({ name: 'login' });
                });
        },
        ...mapActions('auth', {
            doLogout: AUTH_LOGOUT
        }),
    }
};