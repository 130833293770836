import moment from 'moment';
import {
    LEAVEREQUESTLIST_PAGE,
    LEAVEREQUESTLIST_SEARCH,
    LEAVEREQUESTLIST_ENDDATE,
    LEAVEREQUESTLIST_STARTDATE,
    LEAVEREQUESTLIST_STATUS,
    LEAVEREQUESTLIST_YEAR
} from '../actions/leaverequestlistactions';

const state = {
    search: '',
    page: 0,
    startdate: moment().startOf('year').toDate(),
    enddate: moment().endOf('year').toDate(),
    status: null,
    year: moment().year(),
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    startdate: state => state.startdate,
    enddate: state => state.enddate,
    status: state => state.status,
    year: state => state.year
};

const actions = {
    [LEAVEREQUESTLIST_SEARCH]: ({ commit }, search) => {
        commit(LEAVEREQUESTLIST_SEARCH, search);
    },
    [LEAVEREQUESTLIST_PAGE]: ({ commit }, page) => {
        commit(LEAVEREQUESTLIST_PAGE, page);
    },
    [LEAVEREQUESTLIST_STARTDATE]: ({ commit }, startdate) => {
        commit(LEAVEREQUESTLIST_STARTDATE, startdate);
    },
    [LEAVEREQUESTLIST_ENDDATE]: ({ commit }, enddate) => {
        commit(LEAVEREQUESTLIST_ENDDATE, enddate);
    },
    [LEAVEREQUESTLIST_STATUS]: ({ commit }, status) => {
        commit(LEAVEREQUESTLIST_STATUS, status);
    },
    [LEAVEREQUESTLIST_YEAR]: ({ commit }, year) => {
        commit(LEAVEREQUESTLIST_YEAR, year);
    },
};

const mutations = {
    [LEAVEREQUESTLIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [LEAVEREQUESTLIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [LEAVEREQUESTLIST_STARTDATE]: (state, startdate) => {
        state.page = 0;
        state.startdate = startdate;
    },
    [LEAVEREQUESTLIST_ENDDATE]: (state, enddate) => {
        state.page = 0;
        state.enddate = enddate;
    },
    [LEAVEREQUESTLIST_STATUS]: (state, status) => {
        state.page = 0;
        state.status = status;
    },
    [LEAVEREQUESTLIST_YEAR]: (state, year) => {
        state.page = 0;
        state.year = year;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};