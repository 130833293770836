export default {
    name: 'Spinner',
    props: {
        heightclass: {
            type: String,
            default: 'ht-150'
        }
    },
    data() {
        return {
        };
    },
};