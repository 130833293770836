import Vue from 'vue';
import i18n from './i18n.js';
import moment from 'moment';
import 'moment-timezone';

const months = [
    '',
    i18n.t('periods.long.january'),
    i18n.t('periods.long.february'),
    i18n.t('periods.long.march'),
    i18n.t('periods.long.april'),
    i18n.t('periods.long.may'),
    i18n.t('periods.long.june'),
    i18n.t('periods.long.july'),
    i18n.t('periods.long.august'),
    i18n.t('periods.long.september'),
    i18n.t('periods.long.october'),
    i18n.t('periods.long.november'),
    i18n.t('periods.long.december'),
];

Vue.filter('date', value => value == null ? '' : moment(value).tz(i18n.t('format.timezone')).format(i18n.t('format.date')));
Vue.filter('daymonth', value => value == null ? '' : moment(value).tz(i18n.t('format.timezone')).format(i18n.t('format.daymonth')));
Vue.filter('dateshort', value => value == null ? '' : moment(value).tz(i18n.t('format.timezone')).format(i18n.t('format.dateshort')));
Vue.filter('dayanddate', value => value == null ? '' : moment(value).tz(i18n.t('format.timezone')).format(i18n.t('format.dayanddate')));
Vue.filter('day', value => value == null ? '' : moment(value).tz(i18n.t('format.timezone')).format('dd'));
Vue.filter('dateTime', value => value == null ? '' : moment(value).tz(i18n.t('format.timezone')).format(i18n.t('format.dateTime')));
Vue.filter('period', (start, end) => {
    if(!start || ! end) return '';
    let startObj = moment(start).tz(i18n.t('format.timezone'));
    let endObj = moment(end).tz(i18n.t('format.timezone'));
    if(startObj.format('M') == endObj.format('M'))
        return months[startObj.format('M')]+' '+startObj.format('Y');
    if(startObj.format('Y') == endObj.format('Y'))
        return months[startObj.format('M')]+' - '+months[endObj.format('M')]+' '+startObj.format('Y');
    return months[startObj.format('M')]+' '+startObj.format('Y')+' - '+months[endObj.format('M')]+' '+endObj.format('Y');
});
Vue.filter('month', (month,year) => months[month]+' '+year);
Vue.filter('time', value => value == null ? '' : moment(value).tz(i18n.t('format.timezone')).format(i18n.t('format.time')));
Vue.filter('dayOfMonth', value => value == null ? '' : moment(value).format('D'));
Vue.filter('commalist', value=> value.split(',').join(', '));
Vue.filter('nameLong', value=> value==null || !value.lastName?'': `${value.firstName} ${value.middleName?value.middleName+' ':''}${value.lastName}`.trim());
Vue.filter('nameShort', value => value == null || !value.lastName ? '' : `${value.initials} ${value.middleName?value.middleName+' ':''}${value.lastName} : ''}`.trim());
Vue.filter('money', (value, currency) => !value && value !==0 ? '-' : value.toLocaleString('nl-NL', { style: 'currency', currency: currency || 'EUR', useGrouping: true, maximumFractionDigits: 2, minimumFractionDigits: 2 }));
Vue.filter('decimal', (value) => !value && value !==0 ? '-' : value.toLocaleString('nl-NL', { style: 'decimal', useGrouping: true, maximumFractionDigits: 2, minimumFractionDigits: 2 }));
Vue.filter('percentage', (value) => !value && value !==0 ? '-' : (value*100).toLocaleString('nl-NL', { style: 'decimal', useGrouping: true, maximumFractionDigits: 0, minimumFractionDigits: 0 })+'%');
Vue.filter('decimalshort', (value) => !value && value !==0 ? '-' : value.toLocaleString('nl-NL', { style: 'decimal', useGrouping: true, maximumFractionDigits: 2, minimumFractionDigits: 0 }));
