import { DASHBOARDTIMELIST_SEARCH, DASHBOARDTIMELIST_PAGE, DASHBOARDTIMELIST_STATUS } from '../actions/dashboardtimelistactions';

const state = { search: '', page: 0, status: null };

const getters = {
    page: state => state.page,
    search: state => state.search,
    status: state => state.status
};

const actions = {
    [DASHBOARDTIMELIST_SEARCH]: ({ commit }, search) => {
        commit(DASHBOARDTIMELIST_SEARCH, search);
    },
    [DASHBOARDTIMELIST_PAGE]: ({ commit }, page) => {
        commit(DASHBOARDTIMELIST_PAGE, page);
    },
    [DASHBOARDTIMELIST_STATUS]: ({ commit }, status) => {
        commit(DASHBOARDTIMELIST_STATUS, status);
    },
};

const mutations = {
    [DASHBOARDTIMELIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [DASHBOARDTIMELIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [DASHBOARDTIMELIST_STATUS]: (state, status) => {
        state.status = status;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};