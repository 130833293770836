export default {
    components: {
    },
    name: 'InputMoney',
    props: {
        value: Number,
        currency: {
            type: String,
            default: '€'
        },
        readonly: { 
            type: Boolean,
            default: false
        },
        widthClass: {
            type: String,
            default: 'wd-80'
        }
    },
    data() {
        return {
        };
    },
    computed: {
        options() {
            return {
                decimalPlaces: '2',
                digitGroupSeparator: '.',
                decimalCharacter: ',',
                decimalCharacterAlternative: '.',
                currencySymbol: this.currency,
                currencySymbolPlacement: 'p',
                roundingMethod: 'U',
                readOnly: this.readonly
            };
        },
    },
};