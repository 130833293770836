import moment from 'moment';
import {
    PROJECTTIMELIST_PAGE,
    PROJECTTIMELIST_SEARCH,
    PROJECTTIMELIST_ENDDATE,
    PROJECTTIMELIST_STARTDATE
} from '../actions/projectstimelistactions';

const state = {
    search: '',
    page: 0,
    startdate: moment().startOf('month').toDate(),
    enddate: moment().endOf('month').toDate()
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    startdate: state => state.startdate,
    enddate: state => state.enddate,
};

const actions = {
    [PROJECTTIMELIST_SEARCH]: ({ commit }, search) => {
        commit(PROJECTTIMELIST_SEARCH, search);
    },
    [PROJECTTIMELIST_PAGE]: ({ commit }, page) => {
        commit(PROJECTTIMELIST_PAGE, page);
    },
    [PROJECTTIMELIST_STARTDATE]: ({ commit }, startdate) => {
        console.log(startdate);
        commit(PROJECTTIMELIST_STARTDATE, startdate);
    },
    [PROJECTTIMELIST_ENDDATE]: ({ commit }, enddate) => {
        commit(PROJECTTIMELIST_ENDDATE, enddate);
    },
};

const mutations = {
    [PROJECTTIMELIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [PROJECTTIMELIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [PROJECTTIMELIST_STARTDATE]: (state, startdate) => {
        state.page = 0;
        state.startdate = startdate;
    },
    [PROJECTTIMELIST_ENDDATE]: (state, enddate) => {
        state.page = 0;
        state.enddate = enddate;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};