import { render, staticRenderFns } from "./inputtext.vue?vue&type=template&id=9f333cfa&"
import script from "./inputtext.js?vue&type=script&lang=js&"
export * from "./inputtext.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Projecten\\EigenSteil\\MWB Portal\\Frontend\\MwbPortal.Portal\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9f333cfa')) {
      api.createRecord('9f333cfa', component.options)
    } else {
      api.reload('9f333cfa', component.options)
    }
    module.hot.accept("./inputtext.vue?vue&type=template&id=9f333cfa&", function () {
      api.rerender('9f333cfa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ClientApp/components/common/inputs/inputtext.vue"
export default component.exports