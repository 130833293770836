import axios from 'axios';
import querystring from 'qs';
import { AUTH_REQUEST, AUTH_ERROR, AUTH_SUCCESS, AUTH_LOGOUT } from '../actions/authactions';
import * as cookie from 'tiny-cookie';

const USERCOOKIE = 'usersettings';

let user = null;
let employerid = null;
const userCookieValue = cookie.get(USERCOOKIE);
if (userCookieValue)
    user = JSON.parse(userCookieValue);
else
    user = {
        token: null,
        role: null,
        username: null,
        isTimeRegistrationOnly: false
    };

const state = {
    user: user,
    testenvironment: false,
    status: '',
    isAdministrator: user.role === 'Administrator' || user.role === 'AdministratorLimited',
    isLimitedAdministrator: user.role === 'AdministratorLimited',
    isTimeRegistrationOnly: user.isTimeRegistrationOnly
};

const getters = {
    isAuthenticated: state => !!state.user.token,
    authStatus: state => state.status,
    user: state => state.user,
    testenvironment: state => state.testenvironment,
    isAdministrator: state => state.isAdministrator,
    isLimitedAdministrator: state => state.isLimitedAdministrator,
    isTimeRegistrationOnly: state => state.isTimeRegistrationOnly
};

const actions = {
    [AUTH_REQUEST]: ({ commit }, user) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST);
            axios.post(APIURL + '/connect/token', querystring.stringify({ grant_type: 'password', username: user.username, password: user.password, scope: 'roles' }))
                .then(resp => {
                    console.log(resp.data);
                    commit(AUTH_SUCCESS, resp);

                    resolve(resp);
                })
                .catch(err => {
                    commit(AUTH_ERROR, err);
                    reject(err);
                });
        });
    },
    [AUTH_LOGOUT]: ({ commit }) => {
        return new Promise((resolve) => {
            commit(AUTH_LOGOUT);
            resolve();
        });
    },
};

const mutations = {
    [AUTH_REQUEST]: (state) => {
        state.status = 'loading';
    },
    [AUTH_SUCCESS]: (state, resp) => {
        state.status = 'success';
        state.user.token = resp.data.access_token;
        state.user.role = resp.data.role;
        state.user.username = resp.data.username;
        state.user.isTimeRegistrationOnly = resp.data.timeregistrationonly == "1";
        state.isAdministrator = resp.data.role === 'Administrator' || resp.data.role === 'AdministratorLimited';
        state.isLimitedAdministrator = resp.data.role === 'AdministratorLimited';
        cookie.set(USERCOOKIE, JSON.stringify(state.user), Infinity);
        // Here set the header of your ajax library to the token value.
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + resp.data.access_token;
        state.testenvironment = resp.data.testenvironment;
    },
    [AUTH_ERROR]: (state) => {
        state.status = 'error';
        state.user = {
            token: null,
            role: null,
            username: null,
            isTimeRegistrationOnly: false
        };
        cookie.remove(USERCOOKIE);
    },
    [AUTH_LOGOUT]: (state) => {
        state.user = {
            token: null,
            role: null,
            username: null,
            isTimeRegistrationOnly: false
        };
        cookie.remove(USERCOOKIE);
        localStorage.removeItem('user-is-admin');
        // remove the axios default header
        delete axios.defaults.headers.common['Authorization'];
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};