var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.list.doesNotApplyToCurrentEmployee
      ? _c("div", { staticClass: "table-responsive" }, [
          _c(
            "table",
            { staticClass: "table table-hover table-striped mg-b-0" },
            [
              _c("thead", [
                _c("tr", [
                  _c("th", [
                    _vm._v(
                      "\n\n                        " +
                        _vm._s(_vm.$t("year")) +
                        " " +
                        _vm._s(_vm.list.year) +
                        "\n\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.$t("total")))
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.$t("usedbefore")))
                  ]),
                  _vm._v(" "),
                  _vm.showCurrentRequest
                    ? _c("th", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(_vm.$t("currentrequest")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.$t("remaining")))
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("tbody", [
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("leavehours")) +
                        "\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm._f("decimalshort")(_vm.list.leaveHours.total)
                        ) +
                        "\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("decimalshort")(_vm.list.leaveHours.usedBefore)
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _vm.showCurrentRequest
                    ? _c("td", { staticClass: "text-center" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("decimalshort")(
                              _vm.list.leaveHours.currentRequest
                            )
                          )
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("decimalshort")(_vm.list.leaveHours.remaining)
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("schedulefreehours")) +
                        "\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm._f("decimalshort")(
                            _vm.list.scheduleFreeHours.total
                          )
                        ) +
                        "\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("decimalshort")(
                          _vm.list.scheduleFreeHours.usedBefore
                        )
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _vm.showCurrentRequest
                    ? _c("td", { staticClass: "text-center" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("decimalshort")(
                              _vm.list.scheduleFreeHours.currentRequest
                            )
                          )
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("decimalshort")(
                          _vm.list.scheduleFreeHours.remaining
                        )
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("timesavingshours")) +
                        "\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm._f("decimalshort")(
                            _vm.list.timeSavingsHours.total
                          )
                        ) +
                        "\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("decimalshort")(
                          _vm.list.timeSavingsHours.usedBefore
                        )
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _vm.showCurrentRequest
                    ? _c("td", { staticClass: "text-center" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("decimalshort")(
                              _vm.list.timeSavingsHours.currentRequest
                            )
                          )
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("decimalshort")(
                          _vm.list.timeSavingsHours.remaining
                        )
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("shortabsencehours")) +
                        "\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm._f("decimalshort")(
                            _vm.list.shortAbsenceHours.total
                          )
                        ) +
                        "\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("decimalshort")(
                          _vm.list.shortAbsenceHours.usedBefore
                        )
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _vm.showCurrentRequest
                    ? _c("td", { staticClass: "text-center" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("decimalshort")(
                              _vm.list.shortAbsenceHours.currentRequest
                            )
                          )
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("decimalshort")(
                          _vm.list.shortAbsenceHours.remaining
                        )
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("unpaidleave")) +
                        "\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.list.unpaidLeave.total) +
                        "\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("decimalshort")(_vm.list.unpaidLeave.usedBefore)
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _vm.showCurrentRequest
                    ? _c("td", { staticClass: "text-center" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("decimalshort")(
                              _vm.list.unpaidLeave.currentRequest
                            )
                          )
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("decimalshort")(_vm.list.unpaidLeave.remaining)
                      )
                    )
                  ])
                ])
              ])
            ]
          )
        ])
      : _c("div", [_vm._v("\n        Niet van toepassing\n    ")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }