var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "br-logo" },
      [
        _c("router-link", { attrs: { to: "/", exact: true } }, [
          _c("img", { attrs: { src: require("../../images/logo-mwb.png") } })
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "br-sideleft overflow-y-auto" }, [
      _c("label", { staticClass: "sidebar-label pd-x-15 mg-t-15" }, [
        _vm._v(_vm._s(_vm.$t("title.navigation")))
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "br-sideleft-menu" },
        [
          _vm.isAdministrator
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  class: {
                    "active show-sub": _vm.subIsActive(
                      _vm.$t("path.admindashboard")
                    )
                  },
                  attrs: { to: { name: "dashboard" } }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass:
                        "menu-item-icon icon ion-ios-speedometer tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.dashboard")))
                    ])
                  ])
                ]
              )
            : _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  class: {
                    "active show-sub": _vm.subIsActive(
                      _vm.$t("path.employeedashboard")
                    )
                  },
                  attrs: { to: { name: "employeedashboard" } }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass:
                        "menu-item-icon icon ion-ios-speedometer tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.dashboard")))
                    ])
                  ])
                ]
              ),
          _vm._v(" "),
          _vm.isAdministrator
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  class: {
                    "active show-sub": _vm.subIsActive(
                      _vm.$t("path.employeenotification")
                    )
                  },
                  attrs: { to: { name: "employeenotification" } }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-school tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.employeenotification")))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isAdministrator
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  class: {
                    "active show-sub": _vm.subIsActive(_vm.$t("path.projects"))
                  },
                  attrs: { to: { name: "projects" } }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-school tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.projects")))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isAdministrator
            ? _c(
                "a",
                {
                  staticClass: "br-menu-link",
                  class: {
                    "active show-sub": _vm.subIsActive(
                      _vm.$t("path.employeehours")
                    )
                  },
                  attrs: { href: "javascript:;" }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-settings tx-20"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.hours")))
                    ]),
                    _vm._v(" "),
                    _c("i", { staticClass: "menu-item-arrow fa fa-angle-down" })
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isAdministrator
            ? _c("ul", { staticClass: "br-menu-sub nav flex-column" }, [
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          to: { name: "submittedhours" },
                          "active-class": "active"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("title.submittedhours")))]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          to: { name: "unsubmittedhours" },
                          "active-class": "active"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("title.unsubmittedhours")))]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isAdministrator
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  class: {
                    "active show-sub": _vm.subIsActive(
                      _vm.$t("path.timeregistration")
                    )
                  },
                  attrs: { to: { name: "timeregistration" } }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-alarm tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.time")))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isAdministrator && !_vm.isTimeRegistrationOnly
            ? _c(
                "a",
                {
                  staticClass: "br-menu-link",
                  class: {
                    "active show-sub": _vm.subIsActive(_vm.$t("path.leave"))
                  },
                  attrs: { href: "javascript:;" }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-document tx-20"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.leave")))
                    ]),
                    _vm._v(" "),
                    _c("i", { staticClass: "menu-item-arrow fa fa-angle-down" })
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isAdministrator & !_vm.isTimeRegistrationOnly
            ? _c("ul", { staticClass: "br-menu-sub nav flex-column" }, [
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          to: { name: "leaverequest" },
                          "active-class": "active"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("title.leaverequest")))]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          to: { name: "leaveoverview" },
                          "active-class": "active"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("title.leaveoverview")))]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isAdministrator
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  class: {
                    "active show-sub": _vm.subIsActive(
                      _vm.$t("path.employeefiles")
                    )
                  },
                  attrs: { to: { name: "employeefiles" } }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-pin tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.employeefiles")))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isAdministrator
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  class: {
                    "active show-sub": _vm.subIsActive(
                      _vm.$t("path.adminleaverequest")
                    )
                  },
                  attrs: { to: { name: "adminleaverequest" } }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-document tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.leave")))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isAdministrator
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  class: {
                    "active show-sub": _vm.subIsActive(_vm.$t("path.employees"))
                  },
                  attrs: { to: { name: "employees" } }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-people tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.employees")))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isAdministrator
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  class: {
                    "active show-sub": _vm.subIsActive(
                      _vm.$t("path.customersatisfaction")
                    )
                  },
                  attrs: { to: { name: "customersatisfaction" } }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-thumbs-up tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.customersatisfaction")))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isAdministrator && !_vm.isTimeRegistrationOnly
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  class: {
                    "active show-sub": _vm.subIsActive(
                      _vm.$t("path.employeeholidays")
                    )
                  },
                  attrs: { to: { name: "employeeholidays" } }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-thumbs-up tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.holidays")))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isAdministrator
            ? _c(
                "a",
                {
                  staticClass: "br-menu-link",
                  class: {
                    "active show-sub": _vm.subIsActive(_vm.$t("path.settings"))
                  },
                  attrs: { href: "javascript:;" }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-settings tx-20"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.settings")))
                    ]),
                    _vm._v(" "),
                    _c("i", { staticClass: "menu-item-arrow fa fa-angle-down" })
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isAdministrator
            ? _c("ul", { staticClass: "br-menu-sub nav flex-column" }, [
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          to: { name: "documentnotifications" },
                          "active-class": "active"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("title.documentnotifications")))]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: { to: { name: "cao" }, "active-class": "active" }
                      },
                      [_vm._v(_vm._s(_vm.$t("title.cao")))]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          to: { name: "holidays" },
                          "active-class": "active"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("title.holidays")))]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          to: { name: "settingsaccountview" },
                          "active-class": "active"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("title.settingsaccountview")))]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          to: { name: "settingsdocuments" },
                          "active-class": "active"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("title.generalDocument")))]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.isLimitedAdministrator
                  ? _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: {
                              to: { name: "administrator" },
                              "active-class": "active"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("title.administrator")))]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          to: { name: "smslog" },
                          "active-class": "active"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("title.smslog")))]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isAdministrator
            ? _c(
                "a",
                {
                  staticClass: "br-menu-link",
                  class: {
                    "active show-sub": _vm.subIsActive(_vm.$t("path.reports"))
                  },
                  attrs: { href: "javascript:;" }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-settings tx-20"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.reports")))
                    ]),
                    _vm._v(" "),
                    _c("i", { staticClass: "menu-item-arrow fa fa-angle-down" })
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isAdministrator
            ? _c("ul", { staticClass: "br-menu-sub nav flex-column" }, [
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          to: { name: "reportprojecthours" },
                          "active-class": "active"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("title.projecthours")))]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          to: { name: "reportuserhours" },
                          "active-class": "active"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("title.userhours")))]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "br-menu-link",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.logout($event)
                }
              }
            },
            [
              _c("div", { staticClass: "br-menu-item" }, [
                _c("i", {
                  staticClass: "menu-item-icon icon ion-ios-exit tx-22"
                }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("actions.logout")))
                ])
              ])
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }