var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-autonumeric", {
    staticClass: "form-control form-control-sm text-right",
    class: _vm.widthClass,
    attrs: { value: _vm.value, options: _vm.options, readonly: _vm.readonly },
    on: {
      input: function($event) {
        return _vm.$emit("input", $event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }