import axios from 'axios';
import Select2 from 'v-select2-component';
import { strict } from 'assert';

export default {
    components: {
        'select2': Select2
    },
    name: 'SelectEnumType',
    props: {
        value: [Number, String],
        inputclass: {
            type: String,
            default: ''
        },
        emptyname: {
            type: String,
            default: null
        },
        includeInactive: {
            type: Boolean,
            default: false
        },
        enumtype: {
            type: String,
            default: null
        },
        url: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            list: []
        };
    },
    computed: {
        options() {
            return this.list.map(x => {
                return { id: x.id, text: this.$t('enum.' + this.enumtype + '.' + x.name) };
            });
        },
    },
    created() {
        this.fetch();
    },
    methods: {
        fetch() {
            let requesturl = APIURL + this.url;             
            axios.get(requesturl)
                .then(resp => this.list = resp.data);
        },
    }
};