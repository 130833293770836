import { DASHBOARDLEAVELIST_SEARCH, DASHBOARDLEAVELIST_PAGE } from '../actions/dashboardleaverequestlistactions';

const state = { searchleave: '', pageleave: 0 };

const getters = {
    pageleave: state => state.pageleave,
    searchleave: state => state.searchleave
};

const actions = {
    [DASHBOARDLEAVELIST_SEARCH]: ({ commit }, searchleave) => {
        commit(DASHBOARDLEAVELIST_SEARCH, searchleave);
    },
    [DASHBOARDLEAVELIST_PAGE]: ({ commit }, pageleave) => {
        commit(DASHBOARDLEAVELIST_PAGE, pageleave);
    },
};

const mutations = {
    [DASHBOARDLEAVELIST_SEARCH]: (state, searchleave) => {
        state.pageleave = 0;
        state.searchleave = searchleave;
    },
    [DASHBOARDLEAVELIST_PAGE]: (state, pageleave) => {
        state.pageleave = pageleave;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};