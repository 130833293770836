export default {
    name: 'ReadOnlyFormGroup',
    props: {
        name: String,
        labelclass: {
            type: String,
            default: ''
        },
        groupclass: {
            type: String,
            default: 'mg-t-10'
        },
        value: String,
        valueishtml: {
            type:Boolean,
            default: false
        }
    },
    data() {
        return {
        };
    },
};