import Vue from 'vue';

import FormGroup from '../components/common/formgroup.vue';
import FormGroupCheck from '../components/common/formgroupcheck.vue';
import InputNumber from '../components/common/inputs/inputnumber.vue';
import InputCheck from '../components/common/inputs/inputcheck.vue';
import InputText from '../components/common/inputs/inputtext.vue';
import Spinner from '../components/common/spinner.vue';
// import wysiwyg from 'vue-wysiwyg';
import ControlPanel from '../components/common/controlpanel.vue';
import Search from '../components/common/inputs/search.vue';
import Paging from '../components/common/paging.vue';
import DatePicker from '../components/common/inputs/datepicker.vue';
import ReadonlyText from '../components/common/readonly/readonlytext.vue';
import InputTextArea from '../components/common/inputs/inputtextarea.vue';
import TimePicker from '../components/common/inputs/timepicker.vue';
import { default as Vuedals, Component as Vuedal, Bus as VuedalsBus } from 'vuedals';
import InputToggle from '../components/common/inputs/inputtoggle.vue';
import SelectOptions from '../components/common/inputs/selectoptions.vue';
import InputFile from '../components/common/inputs/inputfile.vue';
import ReadOnlyFormGroup from '../components/common/readonly/readonlyformgroup.vue';
import VTooltip from 'v-tooltip'
import Nl2br from 'vue-nl2br';
import ProgressDialog from '../components/common/inputs/progressdialog.vue';
import SelectMonth from '../components/common/inputs/selectmonth.vue';
import InputMoney from '../components/common/inputs/inputmoney.vue';
import VueAutonumeric from 'vue-autonumeric';
import SelectEnumtype from '../components/common/inputs/selectenumtype.vue';
import LeaveStatusTable from '../components/common/leavestatustable.vue';
// import Quill from 'quill';
// import VueQuillEditor from 'vue-quill-editor';
//import ImageResize from 'quill-image-resize-module';
// import QuillEdit from '../components/common/inputs/quill.vue';
// Global components
Vue.component('vue-autonumeric', VueAutonumeric);
Vue.component('selectmonth', SelectMonth);
Vue.component('paging', Paging);
Vue.component('search', Search);
Vue.component('formgroup', FormGroup);
Vue.component('formgroupcheck', FormGroupCheck);
Vue.component('inputnumber', InputNumber);
Vue.component('inputtext', InputText);
Vue.component('inputcheck', InputCheck);
Vue.component('spinner', Spinner);
Vue.component('controlpanel', ControlPanel);
// Vue.use(wysiwyg, { forcePlainTextOnPaste: true });
 Vue.component('datepicker', DatePicker);
Vue.component('readonlytext', ReadonlyText);
Vue.component('inputtextarea', InputTextArea);
Vue.component('selectoptions', SelectOptions);
// Don't forget to call this
Vue.use(TimePicker);
Vue.component('timepicker', TimePicker);
Vue.component('inputtoggle', InputToggle);
Vue.component('inputfile', InputFile);
Vue.component('readonlyformgroup', ReadOnlyFormGroup);
Vue.use(Vuedals);
Vue.component('vuedal', Vuedal);
Vue.use(VTooltip);
Vue.component('nl2br', Nl2br)
// Vue.component('quill', QuillEdit);
Vue.component('progressdialog', ProgressDialog);
Vue.component('inputmoney', InputMoney);
Vue.component('selectenumtype', SelectEnumtype);
Vue.component('leavestatustable', LeaveStatusTable);
// Vue.use(VueQuillEditor);
//Quill.register('modules/imageResize', ImageResize);

// Register a global custom directive called `v-focus`
Vue.directive('focus', {
    inserted: function (el) {
        el.focus();
    }
});

 import VuejsDialog from 'vuejs-dialog';
 Vue.use(VuejsDialog, {
     html: true,
     loader: false,
     okText: 'Ok',
     cancelText: 'Annuleren',
     animation: '',
 });