import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import documentnotificationslist from './modules/documentnotificationslist';
import caolist from './modules/caolist';
import holidayslist from './modules/holidayslist';
import employeelist from './modules/employeelist';
import projectslist from './modules/projectslist';
import customersatisfactionlist from "./modules/customersatisfactionlist";
import timeregistrationlist from './modules/timeregistrationlist';
import projectstimelist from './modules/projectstimelist';
import leaverequestlist from './modules/leaverequestlist';
import caodefinationslist from './modules/caodefinationslist';
import dashboardtimelist from './modules/dashboardtimelist';
import dashboardleaverequestlist from './modules/dashboardleaverequestlist';
import submittedtimelist from './modules/submittedtimelist';
import unsubmittedtimelist from './modules/unsubmittedtimelist';
import administratorlist from './modules/administratorlist';
import smsloglist from './modules/smsloglist';
import projecthourslist from './modules/projecthourslist';
import userhourslist from './modules/userhourslist';
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        documentnotificationslist,
        caolist,
        holidayslist,
        employeelist,
        projectslist,
        customersatisfactionlist,
        timeregistrationlist,
        projectstimelist,
        leaverequestlist,
        caodefinationslist,
        dashboardtimelist,
        dashboardleaverequestlist,
        submittedtimelist,
        unsubmittedtimelist,
        administratorlist,
        smsloglist,
        projecthourslist,
        userhourslist
    },
    strict: !PRODUCTION,
});