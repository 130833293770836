import { AUTH_LOGOUT } from '../../store/actions/authactions';
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
export default {
    data() {
        return {
            yearlysubscription: false
        }
    },
    components: {
    },
    name: 'NavMenu',
    computed: {
        ...mapGetters('auth', [
            'user',
            'isAdministrator',
            'isLimitedAdministrator',
            'isTimeRegistrationOnly'
        ])
    },
    methods: {
        logout: function () {
            this.doLogout()
                .then(() => {
                    this.$router.push({ name: 'login' });
                });
        },
        subIsActive(input) {
            const paths = Array.isArray(input) ? input : [input];
            return paths.some(path => {
                return this.$route.path.indexOf(path) === 0; // current path starts with this path string
            });
        },
        ...mapActions('auth', {
            doLogout: AUTH_LOGOUT
        }),
    }
};