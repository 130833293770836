var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row", class: _vm.groupclass }, [
    _c("div", { staticClass: "col-4 " }, [
      _c("div", { staticClass: "input-group" }, [
        _c(
          "label",
          {
            staticClass: "form-control-label",
            class: _vm.labelclass,
            attrs: { for: _vm.name }
          },
          [_vm._v(_vm._s(_vm.name) + ":")]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-8 " }, [
      !_vm.valueishtml
        ? _c("label", { staticClass: "tx-gray-800" }, [
            _vm._v(_vm._s(_vm.value))
          ])
        : _c("div", {
            staticClass: "tx-gray-800",
            domProps: { innerHTML: _vm._s(_vm.value) }
          })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }