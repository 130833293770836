import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import i18n from './i18n.js';

Vue.use(VueRouter);

const lazyRoutes = {
    login: () => import(/* webpackChunkName: "login" */ '../components/login/login.vue'),
    setPassword: () => import(/* webpackChunkName: "login" */ '../components/login/setpassword.vue'),
    home: () => import(/* webpackChunkName: "home" */ '../components/home/home.vue'),
    editProfile: () => import(/* webpackChunkName: "login" */ '../components/login/editprofile.vue'),
    forgotPassword: () =>
        import( /* webpackChunkName: "login" */ '../components/login/forgotpassword.vue'),


    documentnotifications: () => import(/* webpackChunkName: "settings" */ '../components/settings/documentnotifications/documentnotifications.vue'),
    documentnotificationsList: () => import(/* webpackChunkName: "settings" */ '../components/settings/documentnotifications/list.vue'),
    documentnotificationsEdit: () => import(/* webpackChunkName: "settings" */ '../components/settings/documentnotifications/edit.vue'),

    customersatisfaction: () => import('../components/customersatisfaction/customersatifaction.vue'),
    customersatisfactionList: () => import('../components/customersatisfaction/list.vue'),
    customersatisfactionEdit: () => import('../components/customersatisfaction/edit.vue'),
    customersatisfactiondetails: () => import('../components/customersatisfaction/details.vue'),
    fillsurvey: () => import('../components/fillsurvey/fillsurvey.vue'),
    surveythankyou: () => import('../components/fillsurvey/thankyou.vue'),

    cao: () => import(/* webpackChunkName: "settings" */ '../components/settings/cao/cao.vue'),
    caoList: () => import(/* webpackChunkName: "settings" */ '../components/settings/cao/list.vue'),
    caoEdit: () => import(/* webpackChunkName: "settings" */ '../components/settings/cao/edit.vue'),

    holidays: () => import(/* webpackChunkName: "settings" */ '../components/settings/holidays/holidays.vue'),
    holidaysList: () => import(/* webpackChunkName: "settings" */ '../components/settings/holidays/list.vue'),
    holidaysEdit: () => import(/* webpackChunkName: "settings" */ '../components/settings/holidays/edit.vue'),

    employees: () => import(/* webpackChunkName: "employees" */ '../components/employee/employee.vue'),
    employeesProfile: () => import(/* webpackChunkName: "employees" */ '../components/employee/profile.vue'),
    employeesList: () => import(/* webpackChunkName: "employees" */ '../components/employee/list.vue'),
    employeesEdit: () => import(/* webpackChunkName: "employees" */ '../components/employee/edit.vue'),

    projects: () => import(/* webpackChunkName: "projects" */ '../components/projects/projects.vue'),
    projectsList: () => import(/* webpackChunkName: "projects" */ '../components/projects/list.vue'),
    projectsEdit: () => import(/* webpackChunkName: "projects" */ '../components/projects/edit.vue'),
    projectsTimeList: () => import(/* webpackChunkName: "projects" */ '../components/projects/timelist.vue'),

    timeregistration: () => import(/* webpackChunkName: "projects" */ '../components/timeregistration/timeregistration.vue'),
    timeregistrationList: () => import(/* webpackChunkName: "projects" */ '../components/timeregistration/list.vue'),

    leaverequest: () => import(/* webpackChunkName: "projects" */ '../components/leaverequest/leaverequest.vue'),
    leaverequestList: () => import(/* webpackChunkName: "projects" */ '../components/leaverequest/list.vue'),

    leaveoverview: () => import(/* webpackChunkName: "projects" */ '../components/leaveoverview/leaveoverview.vue'),
    leaveoverviewList: () => import(/* webpackChunkName: "projects" */ '../components/leaveoverview/list.vue'),

    adminleaverequest: () => import(/* webpackChunkName: "projects" */ '../components/adminleaverequest/leaverequest.vue'),
    adminleaverequestList: () => import(/* webpackChunkName: "projects" */ '../components/adminleaverequest/list.vue'),
    adminleaverequestEdit: () => import(/* webpackChunkName: "projects" */ '../components/adminleaverequest/edit.vue'),

    dashboard: () => import(/* webpackChunkName: "projects" */ '../components/dashboard/dashboard.vue'),
    dashboardList: () => import(/* webpackChunkName: "projects" */ '../components/dashboard/list.vue'),

    employeedashboard: () => import(/* webpackChunkName: "projects" */ '../components/employeedashboard/dashboard.vue'),
    employeedashboardList: () => import(/* webpackChunkName: "projects" */ '../components/employeedashboard/list.vue'),

    employeehours: () => import(/* webpackChunkName: "projects" */ '../components/hours/hours.vue'),
    employeehoursList: () => import(/* webpackChunkName: "projects" */ '../components/hours/list.vue'),
    employeehoursEdit: () => import(/* webpackChunkName: "projects" */ '../components/hours/edit.vue'),

    employeefiles: () => import(/* webpackChunkName: "projects" */ '../components/employeefiles/employeefiles.vue'),
    employeefilesList: () => import(/* webpackChunkName: "projects" */ '../components/employeefiles/list.vue'),

    submittedHours: () => import(/* webpackChunkName: "projects" */ '../components/weekhours/submitted/submitted.vue'),
    submittedHoursList: () => import(/* webpackChunkName: "projects" */ '../components/weekhours/submitted/list.vue'),

    unsubmittedHours: () => import(/* webpackChunkName: "projects" */ '../components/weekhours/unsubmitted/unsubmitted.vue'),
    unsubmittedHoursList: () => import(/* webpackChunkName: "projects" */ '../components/weekhours/unsubmitted/list.vue'),

    timeregistrationShare: () => import(/* webpackChunkName: "projects" */ '../components/timeshare/timeshare.vue'),
    timeregistrationShareList: () => import(/* webpackChunkName: "projects" */ '../components/timeshare/list.vue'),

    employeedocumentnotifications: () => import(/* webpackChunkName: "projects" */ '../components/employeenotification/employeenotification.vue'),
    
    employeeholidays: () => import(/* webpackChunkName: "settings" */ '../components/employeeholidays/employeeholidays.vue'),
    employeeholidaysList: () => import(/* webpackChunkName: "settings" */ '../components/employeeholidays/list.vue'),

    settingsAccountView: () => import(/* webpackChunkName: "settings" */ '../components/settings/accountview/accountview.vue'),
    settingsDocuments: () => import(/* webpackChunkName: "settings" */ '../components/settings/documents/profile.vue'),

    administrator: () => import(/* webpackChunkName: "settingsadministrator" */ '../components/settings/administrator/administrator.vue'),
    administratorList: () => import(/* webpackChunkName: "settingsadministrator" */ '../components/settings/administrator/list.vue'),
    administratorEdit: () => import(/* webpackChunkName: "settingsadministrator" */ '../components/settings/administrator/edit.vue'),


    smslog: () => import(/* webpackChunkName: "settingsadministrator" */ '../components/smslog/smslog.vue'),
    smslogList: () => import(/* webpackChunkName: "settingsadministrator" */ '../components/smslog/list.vue'),

    reports: () => import(/* webpackChunkName: "reports" */ '../components/reports/reports.vue'),
    reportProjectHours: () => import(/* webpackChunkName: "reports" */ '../components/reports/projecthours/reports.vue'),
    reportUserHours: () => import(/* webpackChunkName: "reports" */ '../components/reports/userhours/reports.vue'),


};


const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters['auth/isAuthenticated']) {
        next();
        return;
    }
    next('/');
};

const ifAuthenticated = (to, from, next) => {
    if (store.getters['auth/isAuthenticated']) {
        next();
        return;
    }
    next('/login');
};

const routes = [
    {
        name: 'login',
        path: i18n.t('path.login'),
        component: lazyRoutes.login,
        beforeEnter: ifNotAuthenticated,
        meta: { title: 'title.portal'}
    },
    {
        name: 'fillsurvey',
        path: i18n.t('path.fillsurvey'),
        component: lazyRoutes.fillsurvey,
        meta: { title: 'title.fillsurvey' }
    },
    {
        name: 'surveythankyou',
        path: i18n.t('path.surveythankyou'),
        component: lazyRoutes.surveythankyou,
        meta: { title: 'title.fillsurvey' }
    },
    {
        name: 'forgotpassword',
        path: i18n.t('path.forgotpassword'),
        component: lazyRoutes.forgotPassword,
        meta: { title: 'title.portal' }
    },
    {
        name: 'home',
        path: i18n.t('path.home'),
        component: lazyRoutes.home,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'editprofile',
        path: i18n.t('path.editprofile'),
        component: lazyRoutes.editProfile,
        beforeEnter: ifAuthenticated,
        meta: { title: 'title.changepassword' }
    },
    {
        name: 'setpassword',
        path: i18n.t('path.setpassword'),
        component: lazyRoutes.setPassword,
        meta: { title: 'title.setpassword' }
    },
    {
        path: i18n.t('path.settings_documentnotifications'),
        component: lazyRoutes.documentnotifications,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'documentnotifications',
                path: '',
                component: lazyRoutes.documentnotificationsList,
                meta: { title: 'title.documentnotifications' },

            },
            {
                name: 'documentnotificationsEdit',
                path: ':id',
                component: lazyRoutes.documentnotificationsEdit,
                meta: { title: 'title.documentnotifications' },

            },
        ]
    },
    {
        path: i18n.t('path.settings_cao'),
        component: lazyRoutes.cao,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'cao',
                path: '',
                component: lazyRoutes.caoList,
                meta: { title: 'title.cao' },

            },
            {
                name: 'caoEdit',
                path: ':id',
                component: lazyRoutes.caoEdit,
                meta: { title: 'title.cao' },

            },
        ]
    },
    {
        path: i18n.t('path.settings_holidays'),
        component: lazyRoutes.holidays,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'holidays',
                path: '',
                component: lazyRoutes.holidaysList,
                meta: { title: 'title.holidays' },

            },
            {
                name: 'holidaysEdit',
                path: ':id',
                component: lazyRoutes.holidaysEdit,
                meta: { title: 'title.holidays' },

            },
        ]
    },
    {
        path: i18n.t('path.employeeholidays'),
        component: lazyRoutes.employeeholidays,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'employeeholidays',
                path: '',
                component: lazyRoutes.employeeholidaysList,
                meta: { title: 'title.holidays' },

            },
            
        ]
    },
    {
        path: i18n.t('path.employees'),
        component: lazyRoutes.employees,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'employees',
                path: '',
                component: lazyRoutes.employeesList,
                meta: { title: 'title.employees' },

            },
            {
                name: 'employeesEdit',
                path: ':id',
                component: lazyRoutes.employeesEdit,
                meta: { title: 'title.employees' },

            },
            {
                name: 'employeesProfile',
                path: ':id/profiel',
                component: lazyRoutes.employeesProfile,
                meta: { title: 'title.employees' },

            },
        ]
    },
    {
        path: i18n.t('path.projects'),
        component: lazyRoutes.projects,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'projects',
                path: '',
                component: lazyRoutes.projectsList,
                meta: { title: 'title.projects' },

            },
            {
                name: 'projectsEdit',
                path: ':id',
                component: lazyRoutes.projectsEdit,
                meta: { title: 'title.projects' },

            },
            {
                name: 'projectsTime',
                path: ':id/tijd',
                component: lazyRoutes.projectsTimeList,
                meta: { title: 'title.projects' },

            },
        ]
    },
    {
        path: i18n.t('path.customersatisfaction'),
        component: lazyRoutes.customersatisfaction,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'customersatisfaction',
                path: '',
                component: lazyRoutes.customersatisfactionList,
                meta: { title: 'title.customersatisfaction' },

            },
            {
                name: 'customersatisfactionEdit',
                path: ':id',
                component: lazyRoutes.customersatisfactionEdit,
                meta: { title: 'title.customersatisfaction' },
                props: true
            },

            {
                name: 'customersatisfactionDetails',               
                path: ':id/details',
                component: lazyRoutes.customersatisfactiondetails,
                meta: { title: 'title.customersatisfaction' },
                props: true
            }
        ]
    },
    {
        path: i18n.t('path.timeregistration'),
        component: lazyRoutes.timeregistration,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'timeregistration',
                path: '',
                component: lazyRoutes.timeregistrationList,
                meta: { title: 'title.time' },
                props: true

            },

        ]
    },
    {
        path: i18n.t('path.leaverequest'),
        component: lazyRoutes.leaverequest,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'leaverequest',
                path: '',
                component: lazyRoutes.leaverequestList,
                meta: { title: 'title.leave' },

            },

        ]
    },
    {
        path: i18n.t('path.leaveoverview'),
        component: lazyRoutes.leaveoverview,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'leaveoverview',
                path: '',
                component: lazyRoutes.leaveoverviewList,
                meta: { title: 'title.leave' },

            },

        ]
    },
    {
        path: i18n.t('path.adminleaverequest'),
        component: lazyRoutes.adminleaverequest,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'adminleaverequest',
                path: '',
                component: lazyRoutes.adminleaverequestList,
                meta: { title: 'title.leave' },

            },
            {
                name: 'adminleaverequestEdit',
                path: ':id',
                component: lazyRoutes.adminleaverequestEdit,
                meta: { title: 'title.leave' },

            },
        ]
    },
    {
        path: i18n.t('path.admindashboard'),
        component: lazyRoutes.dashboard,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'dashboard',
                path: '',
                component: lazyRoutes.dashboardList,
                meta: { title: 'title.dashboard' },

            },

        ]
    },
    {
        path: i18n.t('path.employeedashboard'),
        component: lazyRoutes.employeedashboard,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'employeedashboard',
                path: '',
                component: lazyRoutes.employeedashboardList,
                meta: { title: 'title.dashboard' },

            },

        ]
    },
    {
        path: i18n.t('path.employeefiles'),
        component: lazyRoutes.employeefiles,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'employeefiles',
                path: '',
                component: lazyRoutes.employeefilesList,
                meta: { title: 'title.employeefiles' },

            },

        ]
    },
    {
        path: i18n.t('path.employeehours'),
        component: lazyRoutes.employeehours,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'employeehours',
                path: '',
                component: lazyRoutes.employeehoursList,
                meta: { title: 'title.hours' },
                props: true
            },
            {
                name: 'employeehoursEdit',
                path: ':timeweekId',
                component: lazyRoutes.employeehoursEdit,
                meta: { title: 'title.hours' },
                props: true
            },

        ]
    },    
    {
        path: i18n.t('path.submittedhours'),
        component: lazyRoutes.submittedHours,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'submittedhours',
                path: '',
                component: lazyRoutes.submittedHoursList,
                meta: { title: 'title.hours' },
                props: true
            },           

        ]
    },
    {
        path: i18n.t('path.unsubmittedhours'),
        component: lazyRoutes.unsubmittedHours,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'unsubmittedhours',
                path: '',
                component: lazyRoutes.unsubmittedHoursList,
                meta: { title: 'title.hours' },
                props: true
            },

        ]
    },
    {
        path: i18n.t('path.timeshare'),
        component: lazyRoutes.timeregistrationShare,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'timeshare',
                path: '',
                component: lazyRoutes.timeregistrationShareList,
                meta: { title: 'title.timeregistrationshare' },
                props: true
            },

        ]
    },
    {
        name: 'employeenotification',
        path: i18n.t('path.employeenotification'),
        component: lazyRoutes.employeedocumentnotifications,
        beforeEnter: ifAuthenticated,
    },
    {
        name: 'settingsaccountview',
        path: i18n.t('path.settingsaccountview'),
        component: lazyRoutes.settingsAccountView,
        meta: { title: 'title.settingsaccountview' },
        beforeEnter: ifAuthenticated
    },
    {
        name: 'settingsdocuments',
        path: i18n.t('path.settingsdocuments'),
        component: lazyRoutes.settingsDocuments,
        meta: { title: 'title.settingsdocuments' },
        beforeEnter: ifAuthenticated
    },
    {
        path: i18n.t('path.settings_administrator'),
        component: lazyRoutes.administrator,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'administrator',
                path: '',
                component: lazyRoutes.administratorList,
                meta: { title: 'title.administrator' }
            },
            {
                name: 'administratorEdit',
                path: ':id',
                component: lazyRoutes.administratorEdit,
                meta: { title: 'title.administrator' }
            }
        ]
    },
    {
        path: i18n.t('path.smslog'),
        component: lazyRoutes.smslog,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'smslog',
                path: '',
                component: lazyRoutes.smslogList,
                meta: { title: 'title.smslog' }
            },
            //{
            //    name: 'administratorEdit',
            //    path: ':id',
            //    component: lazyRoutes.administratorEdit,
            //    meta: { title: 'title.administrator' }
            //}
        ]
    },
    {
        path: i18n.t('path.reports'),
        component: lazyRoutes.reports,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'reportprojecthours',
                path: i18n.t('path.reportprojecthours'),
                component: lazyRoutes.reportProjectHours,
                meta: { title: 'title.projecthours' }
            },
            {
                name: 'reportuserhours',
                path: i18n.t('path.reportuserhours'),
                component: lazyRoutes.reportUserHours,
                meta: { title: 'title.userhours' }
            },
        ]
    }
];

const router = new VueRouter({ mode: 'history', routes });

router.afterEach((to, from) => {
    document.title = i18n.t(to.meta.title);
});

export default router;