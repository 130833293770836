import moment from 'moment';
import {
    TIMEREGISTRATIONLIST_PAGE,
    TIMEREGISTRATIONLIST_SEARCH,
    TIMEREGISTRATIONLIST_WEEK,
    TIMEREGISTRATIONLIST_YEAR
} from '../actions/timeregistrationlistactions';

const state = {
    search: '',
    page: 0,
    year: moment(new Date(), "YYYY").toDate().getFullYear(),
    week: moment(new Date(), "MMDDYYYY").isoWeek()
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    year: state => state.year,
    week: state => state.week,
};

const actions = {
    [TIMEREGISTRATIONLIST_SEARCH]: ({ commit }, search) => {
        commit(TIMEREGISTRATIONLIST_SEARCH, search);
    },
    [TIMEREGISTRATIONLIST_PAGE]: ({ commit }, page) => {
        commit(TIMEREGISTRATIONLIST_PAGE, page);
    },
    [TIMEREGISTRATIONLIST_YEAR]: ({ commit }, year) => {
        commit(TIMEREGISTRATIONLIST_YEAR, year);
    },
    [TIMEREGISTRATIONLIST_WEEK]: ({ commit }, week) => {
        commit(TIMEREGISTRATIONLIST_WEEK, week);
    },
};

const mutations = {
    [TIMEREGISTRATIONLIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [TIMEREGISTRATIONLIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [TIMEREGISTRATIONLIST_YEAR]: (state, year) => {
        state.page = 0;
        state.year = year;
    },
    [TIMEREGISTRATIONLIST_WEEK]: (state, week) => {
        state.page = 0;
        state.week = week;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};